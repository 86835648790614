import React from 'react';
import PreHeader from '../components/PreHeader'
import Header from '../components/Header'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import CTA from '../components/CTA'
import neon from '../images/open-neon-signage-turned-on-2995188.png'
import help from '../images/HeretoHelpLogo_-1@2x.png'
import notes from '../images/people-notes-meeting-team-1.png'
import podcast from '../images/close-up-fashion-female-girl-1.png'
import covid from '../images/COVID-1.png'
export default () => (
    <div id="app">
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="Description" content="WRAL Digital Solutions Refresh Advertise With Us Page"></meta>
          <title>Advertise with WRAL | WRAL Digital Solutions</title>
          <link rel="canonical" href="https://www.wraldigitalsolutions.com/business-resources/" />
        </Helmet>
      <PreHeader/>
      <Header/>
      <h1 className="business--title">Business Resources</h1>
      <div className="business">
        <div className = "business_wrapper">
          <img src={neon} className="business--image" alt="advertising mobile mockups"/>
          <div className = "business_grid-top">
            <div className= "business_block">
              <h3 className="business_block--title">How our community is coming together amid COVID-19</h3>
              <p className="business_block--text">CBC has a long history of being a community resource and providing coverage you can count on. These times are no different. With a mission of “facts over fear” you can get the latest coronavirus updates here. For additional extended news coverage, check out this page or sign up for the Coronavirus Newsletter. Is listening more your speed? We also have a Daily Coronavirus podcast.</p>
              <p className="business_block--text">Additionally, we believe in supporting our community during times of crisis. Check out the following links, which are updated daily:</p>
              <p className="business_block--list">• <a href="https://www.wral.com/Support-local-raleigh-durham-businesses/19014546/" target="_blank">Support local businesses</a></p>
              <p className="business_block--list">• <a href="https://www.wral.com/Triangle-restaurants-offering-catering-delivery-and-take-out/19016220/" target="_blank">Local restaurants you can support</a></p>
              <p className="business_block--list">• <a href="https://www.wral.com/lost-your-job-due-to-coronavirus-slowdown-here-s-how-to-get-help/19010660/" target="_blank">Ways you can help your neighbors</a></p>
              <p className="business_block--list">• <a href="https://www.wral.com/list-of-resources-to-help-seniors-get-groceries-and-prescriptions/19015969/" target="_blank">Resources to help seniors get groceries and prescriptions</a></p>
              <p className="business_block--list">• <a href="https://www.wral.com/coronavirus/donate-united-way-wral-drive-helps-our-community-through-coronavirus-crisis/19010660/" target="_blank">Ways to support individuals and non-profits</a></p>
            </div>
          </div>
        </div>

        <div className = "business_wrapper">
          <div className = "business_grid-top">
            <div className= "business_block">
              <h3 className="business_block--title">“Here to Help” virtual conference series</h3>
              <p className="business_block--text">As a locally owned company, it has never been more important for us to be there for our community and our clients.</p>
              <p className="business_block--text">Capitol Broadcasting Company and Sunrise stations are holding our Here to Help: Local Business Virtual Conference Series Thursdays at 2 p.m.</p>
              <p className="business_block--text">Tune in to get free advice from local experts and hear ideas from local business owners on what you can do to keep your business alive during a crisis.</p>
                <div className="business_cta">
                    <a href="https://register.gotowebinar.com/register/6546099012389619724?utm_source=wralds&utm_medium=wralds&utm_campaign=wral%20newletter" target="_blank"><button type="button" className="cta_contact-us--desktop">Register</button></a>
                    <a href="https://register.gotowebinar.com/register/6546099012389619724?utm_source=wralds&utm_medium=wralds&utm_campaign=wral%20newletter" target="_blank"><button type="button" className="cta_contact-us--mobile">Register</button></a>
                </div>
            </div>
          </div>
          <img src={help} className="business--image" alt="advertising mobile mockups"/>
        </div>

        <div className = "business_wrapper">
              <h3 className="business_block--title-header">Additional Resources</h3>
        </div>

        <div className = "business_wrapper">
          <div className = "business_grid">
            <div className= "business_block">
              <h3 className="business_block--title">Tell us how you’re pivoting your business to serve customers</h3>
              <img src={notes} className="business--image-resources" alt="advertising mobile mockups"/>
              <p className="tv-radio_text">We know that businesses are struggling and also adapting and innovating in new and unexpected ways. We’d love to hear how your local business is responding to the impacts of the coronavirus.</p>
                <div className="business_cta">
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdgKdnRUzNJTz82FcBOU3_YuUr9CeD9I2QCS-2iJzYMcEPXpw/viewform" target="_blank"><button type="button" className="cta_contact-us--desktop">Let us know</button></a>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdgKdnRUzNJTz82FcBOU3_YuUr9CeD9I2QCS-2iJzYMcEPXpw/viewform" target="_blank"><button type="button" className="cta_contact-us--mobile">Let us know</button></a>
                </div>
            </div>
            <div className= "business_block">
              <h3 className="business_block--title">Subscribe to our Cororanvirus podcast</h3>
              <br></br>
              <img src={podcast} className="business--image-resources" alt="advertising mobile mockups"/>
              <p className="tv-radio_text">Get all the latest news and information on the virus and how it is impacting North Carolina.</p>
                <div className="business_cta">
                    <a href="https://www.capitolbroadcasting.com/2020/03/18/new-daily-podcast-from-wral-tv-about-coronavirus/" target="_blank"><button type="button" className="cta_contact-us--desktop">Subscribe</button></a>
                    <a href="https://www.capitolbroadcasting.com/2020/03/18/new-daily-podcast-from-wral-tv-about-coronavirus/" target="_blank"><button type="button" className="cta_contact-us--mobile">Subscribe</button></a>
                </div>
            </div>
            <div className= "business_block">
              <h3 className="business_block--title">Subscribe to our daily Coronavirus newsletter</h3>
              <br></br>
              <img src={covid} className="business--image-resources" alt="advertising mobile mockups"/>
              <p className="tv-radio_text">Once a day, receive an email detailing all of the recent news and updates on Coronavirus and its impact on life and business in our great state.</p>
                <div className="business_cta">
                    <a href="https://www.wral.com/coronavirus" target="_blank"><button type="button" className="cta_contact-us--desktop">Sign Up</button></a>
                    <a href="https://www.wral.com/coronavirus" target="_blank"><button type="button" className="cta_contact-us--mobile">Sign Up</button></a>
                </div>
            </div>
          </div>
        </div>

        <div className="business_banner">
            <h3 className="business_banner-text">
              To get started today <a href="tel:+1-919-744-3030">call</a> or fill out our contact form.
            </h3>
          <div className="advertising_cta">
              <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--mobile"/>
              <CTA text="Contact Us" type="button" link="/contact" classname="contact-us--desktop"/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );